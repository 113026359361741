import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 日报管理API接口
// --------------------------------------------

/**
 * 获取日报列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getDaily = async data => {
  return await $.get({
    url: 'admin/daily/dailyList',
    data,
    isLoad: true,
  })
}

/**
 * 添加日报
 * @param {Object} data 详看接口文档
 */
export const addDaily = async data => {
  return await $.post({
    url: 'admin/daily/dailyAdd',
    data,
  })
}

/**
 * 更新日报
 * @param {Object} data 详看接口文档
 */
export const updDaily = async data => {
  return await $.post({
    url: 'admin/daily/dailyEdit',
    data,
  })
}

/**
 * 删除日报
 * @param {String} id 日报id
 */
export const delDaily = async id => {
  return await $.get({
    url: 'admin/daily/dailyDel',
    data: { id },
  })
}

/**
 * 获取日报详情
 * @param {String} id 日报id
 */
export const getDailyDetail = async id => {
  return await $.get({
    url: 'admin/daily/dailyEdit',
    data: { id },
  })
}

/**
 * 获取人员
 */
export const getDailySelect = async () => {
  return await $.get({
    url: 'admin/common/userList',
  })
}

/**
 * 发布日报
 * @param {Object} data 详看接口文档
 */
export const relDaily = async data => {
  return await $.post({
    url: 'admin/daily/dailyIssue',
    data,
  })
}
