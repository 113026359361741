<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="title"
    :visible.sync="isShow"
  >
    <div style="justify-content: space-between;"  class="all-input">
      <div class="item-input">
        <div class="label">人员名称：</div>
        <el-input
          size="small"
          v-model="text"
          @input="changeInput"
          class="mini"
        ></el-input>
        <el-button
          style="margin-bottom: 5px"
          size="small"
          class="search"
          type="primary"
          @click="search"
          >搜索</el-button
        >
      </div>
      <div class="item-input">
        <el-button
          style="margin-bottom: 5px"
          size="small"
          class="search"
          type="warning"
          @click="resetChecked"
          >清空已选</el-button
        >
      </div>
      <div style="width: 100%;" class="item-input tree-s">
        <div style="margin-left: 8px;" class="label"><span class="red">*</span>选择人员：</div>
        <el-tree
          class="big"
          :data="data"
          :props="defaultProps"
          node-key="id"
          default-expand-all
          :filter-node-method="filterNode"
          :show-checkbox="true"
          ref="tree"
        >
        </el-tree>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getDailySelect, relDaily } from '@/api/daily'
export default {
  name: 'selectPeople',
  props: {
    title: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      isShow: false, // 是否打开弹窗
      text: '',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'title',
      },
      id: 0,
    }
  },
  methods: {
    open: function (id) {
      this.id = id
      this.isShow = true
      getDailySelect().then(res => {
        console.log(res, '这是分类')
        const { result } = res
        this.data = result
      })
    },
    close: function () {
      this.isShow = false
    },
    submit: function() {
      const list = this.$refs.tree.getCheckedKeys()
      if (!list.length) {
        this.$toast('请选择人员')
        return false
      }
      relDaily({
        user_id: list,
        d_id: this.id,
      }).then(res => {
        this.$toast(res.message)
        this.close()
      })
    },
    search: function () {
      this.$refs.tree.filter(this.text)
    },
    changeInput: function (e) {
      this.$forceUpdate()
    },

    filterNode(value, data) {
      if (!value) return true
      return data.title.indexOf(value) !== -1
    },

    resetChecked() {
      this.$refs.tree.setCheckedKeys([])
    },
  },
}
</script>

<style lang="stylus" scoped>
.search {
  margin-left: 24px;
}

.all-input {
  // justify-content: space-between;
  width 100%;
  .tree-s {
    align-items: flex-start;

    .big {
      height: 388px !important;
      // width: 609px !important;
      border: 1px solid #DCDEE2;
      border-radius: 3px;
      overflow auto;
      width 100% !important;
    }
  }
}
</style>
