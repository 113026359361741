<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="`${isEdit ? '编辑' : '添加'}日报`"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div style="justify-content: flex-start;" class="item-input">
        <div style="width: 87px;" class="label"><span class="red">*</span>日报名称：</div>
        <el-input v-model="formData.daily_name" class="mini"></el-input>
      </div>
      <div style="align-items: flex-start;" class="item-input">
        <div style="width: 33%;" class="label"><span class="red">*</span>日报内容：</div>
        <TEditor v-model="formData.daily_content"></TEditor>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit"
        >确 定</el-button
      >
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addDaily, updDaily } from '@/api/daily'
import { content } from './template'
export default {
  name: 'addCheck',
  props: {
    loadChange: Function,
  },
  data() {
    return {
      isShow: false, // 是否打开弹窗
      formData: {
        daily_name: '', // 名称
        daily_content: content, // 内容
      },
      toastData: {
        daily_name: '请输入日报名称', // 名称
        daily_content: '请输入日报内容', // 内容
      },
      isEdit: false,
    }
  },
  created() {},
  methods: {

    open: function (data) {
      this.isShow = true
      this.isEdit = false
      if (data) {
        // eslint-disable-next-line camelcase
        const { daily_name, daily_content, id } = data
        this.formData = {
          daily_name,
          daily_content,
          id,
        }
        this.isEdit = true
        this.isSave = true
      }
    },
    close: function (isLoad) {
      this.isShow = false
      this.clearFormData()
    },

    submit: function () {
      for (const key in this.toastData) {
        if (!this.formData[key]) {
          this.$toast(this.toastData[key])
          return false
        }
      }

      console.log(this.formData, '这是表单')
      if (!this.isEdit) this.saveDaily()
      else this.editDaily()
    },

    saveDaily: function () {
      addDaily(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },

    editDaily: function () {
      updDaily(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },

    clearFormData: function () {
      for (const key in this.formData) {
        this.formData[key] = ''
      }
      this.formData.daily_content = content
      this.fileList = []
    },
  },
}
</script>

<style lang="stylus" scoped>
.all-input {
  // justify-content: space-between;

  .file {
    align-items: flex-start;
  }
}
</style>
