<template>
  <div >
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

    <add-daily :loadChange='_loadData' ref="addD"></add-daily>
    <select-people ref="selP"></select-people>
    <div style="" class="html" v-html="html"></div>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import addDaily from './components/addDaily' // 添加
import { getDaily, delDaily, getDailyDetail } from '@/api/daily'
import selectPeople from './components/selectPeople'
// eslint-disable-next-line no-unused-vars
import saveAs from 'file-saver'
import '@/js/jquery.wordexport'
export default {
  name: 'ProjectList',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
    addDaily,
    selectPeople,
  },
  data() {
    return {
      searchArr: [
        {
          name: '创建时间',
          type: 'date',
          key: 'createTime',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加日报',
          func: 'openAddDaily',
          isLoading: false,
        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeDaily',
          isLoading: false,
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'daily_code',
          label: '编号',
          width: '200',
        },
        {
          prop: 'daily_name',
          label: '日报名称',
          width: '200',
        },
        {
          prop: 'daily_uptime',
          label: '更新时间',
          width: '200',
        },
        {
          prop: 'daily_issuetime',
          label: '发布时间',
          width: '200',
        },
        {
          prop: 'daily_addtime',
          label: '创建时间',
          width: '200',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        // {
        //   type: '',
        //   name: '查看',
        //   func: '',
        // },
        {
          type: '',
          name: '发布',
          func: 'openSelectPeople',
        },
        {
          type: '',
          name: '下载',
          func: 'downDaily',
        },
        {
          type: '',
          name: '编辑',
          func: 'openAddDailyDetail',
        },
        {
          type: 'danger',
          name: '删除',
          isLoading: false,
          func: 'removeDaily',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        start_time: '',
        end_time: '',
      },
      ids: '', // 要删除的id
      tabsIndex: 0, // tabs 按钮的索引
      html: '',
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.dataArr = []
      getDaily(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach((item) => {
          item.buttons = this.butnArr
          item.is_auth = item.is_auth ? '授权' : '未授权'
        })
        this.dataArr = result.data
      })
    },

    openSelectPeople: function() {
      this.$refs.selP.open(this.ids)
    },

    downDaily: function(item) {
      this.html = item.daily_content
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        $('.html').wordExport(item.daily_name)
      }, 2000)
    },

    openAddDailyDetail: function({ id }) {
      getDailyDetail(id).then(res => {
        this.$refs.addD.open(res.result.data)
      })
    },

    // 打开新增日报弹窗
    openAddDaily: function() {
      this.loading()
      this.$refs.addD.open()
    },

    // 删除日报
    removeDaily: function({ id }) {
      if (!this.ids) {
        this.$toast('请选择要删除的日报')
        !id && this.loading()
        return false
      }
      delDaily(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
        !id && this.loading()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { createTime = ['', ''] } = data
      console.log(createTime, '::::zhe')
      this.formData.start_time = createTime[0]
      this.formData.end_time = createTime[1]
      this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this.loading(index)
      this[item.func](item)
    },

    // 打开或关闭tabs 按钮loading
    loading: function(index) {
      index && (this.tabsIndex = index)
      this.tabsArr[this.tabsIndex].isLoading = !this.tabsArr[this.tabsIndex].isLoading
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },
    // 收到 表格 内方法 回调
    onCallbackButton: function(item, func) {
      this.ids = item.id
      this[func](item)
    },
    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped>
.html {
  opacity 0
}
</style>
